import React from "react";
import {
  Routes,
  Route,
  useParams,
  useLocation
} from "react-router-dom";
import { useMediaQuery } from 'react-responsive'

import './App.scss';

import Frontpage from './pages/frontpage.page';
import PortfolioPage from './pages/portfolio.page';
import AboutPage from './pages/about.page';
import VenuesPage from './pages/venues.page';
import ServicesPage from './pages/services.page';
import ContactPage from './pages/contact.page';
import WeddingPage from './pages/wedding.page';

function App() {
  // let location = useLocation()
  // console.log(location)
  const isSmall = useMediaQuery({
    query: '(max-width: 1000px)'
  })
  return (
    <div>
      <Routes>
          <Route path="/" element={<Frontpage isSmall={isSmall} />} />
          <Route path="/about" element={<AboutPage isSmall={isSmall} />} />
          <Route path="/services" element={<ServicesPage isSmall={isSmall} />} />
          <Route path="/venues" element={<VenuesPage isSmall={isSmall} />} />
          <Route path="/contact" element={<ContactPage isSmall={isSmall} />} />
          <Route path="/portfolio/*" element={<WeddingPage isSmall={isSmall} />} />
          <Route exact path="/portfolio" element={<PortfolioPage isSmall={isSmall} />} />
      </Routes>
    </div>
  );
}

export default App;
