import React, { useEffect, useState, useRef } from "react";

import "./full-screen.styles.scss";

const FullScreen = ({ imageUrl, title }) => {
  console.log(imageUrl);
  return (
    <div className="full-screen-wrapper">
      <div className="full-screen-title">{title}</div>
      <div className="full-screen-image">
        <img src={imageUrl} />
      </div>
    </div>
  );
};

export default FullScreen;
