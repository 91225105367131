import React, { useEffect, useState, useRef } from "react";
import { RiMenuFill } from "react-icons/ri";

import { useLocation, Link } from "react-router-dom";

import "./menu.styles.scss";

const SmallMenu = () => {
  const Accordion = ({ children }) => {
    const [isOpen, setOpen] = React.useState(false);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          <div className="small-menu-wrapper-logo">
            <img
              className="menu-logo"
              src={process.env.PUBLIC_URL + "/img/logo_icon_01.png"}
            />
          </div>
          <div className="small-menu-icon">
            <RiMenuFill />
          </div>
        </div>
        <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="small-menu-wrapper">
      <Accordion>
        <div className="small-menu-wrapper-component">
          <Link to={`/`}>Home</Link>
        </div>

        <div className="small-menu-wrapper-component">
          <Link to={`/about`}>About</Link>
        </div>
        <div className="small-menu-wrapper-component">
          <Link to={`/portfolio`}>Portfolio</Link>
        </div>
        <div className="small-menu-wrapper-component">
          <Link to={`/services`}>Services</Link>
        </div>
        <div className="small-menu-wrapper-component">
          <Link to={`/venues`}>Venues</Link>
        </div>
        <div className="small-menu-wrapper-component">
          <Link to={`/contact`}>Get in touch</Link>
        </div>
      </Accordion>
    </div>
  );
};

export default SmallMenu;
