import React, { useEffect, useState, useRef } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import "./page.styles.scss";

import { useLocation, Link } from "react-router-dom";

import Menu from "../components/menu.component";
import SmallMenu from "../components/menu-small.component";
import HalfScreen from "../components/half-screen.component";
import Footer from "../components/footer.component";

const WeddingPage = ({ isSmall }) => {
  const [pageContent, setpageContent] = useState(null);
  const [galleryImages, setGalleryImages] = useState(null);
  const [pageText, setPageText] = useState(null);
  const [weddingTitle, setWeddingTitle] = useState(null);
  const location = useLocation();
  const [index, setIndex] = useState(-1);

  useEffect(() => {
    console.log(location);
    const cleanLocation = location.pathname.replace("/portfolio/", "");
    const fullLocation =
      "https://dreamingoutloud.it/wordpress/wp-json/wp/v2/posts?slug=" +
      cleanLocation;
    // console.log(cleanLocation);
    if (!cleanLocation) return;
    else {
      async function loadPage() {
        // console.log("loading page", cleanLocation);
        const response = await fetch(fullLocation);
        if (!response.ok) {
          console.log("error");
          // oups! something went wrong
          return;
        }

        const posts = await response.json();
        setpageContent(posts);
      }
      loadPage();
      //   const currentArtist = pageContent.artists.filter((artist) => {
      //     return artist.slug.current == cleanLocation;
      //   });
      //   setDisplayArtist(currentArtist);
    }
  }, []);

  function extractImgAttributes(html) {
    // Parse the HTML string into a DOM document
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Select all img elements within figure elements
    const figureElements = doc.querySelectorAll("figure");

    // Extract the desired attributes of each img element and the figcaption text
    const imgAttributes = Array.from(figureElements).map((figure) => {
      const img = figure.querySelector("img");
      const figcaption = figure.querySelector("figcaption");
      return {
        src: img ? img.getAttribute("src") : null,
        width: img ? img.getAttribute("width") || null : null,
        height: img ? img.getAttribute("height") || null : null,
        caption: figcaption ? figcaption.textContent : null,
      };
    });

    return imgAttributes;
  }

  function extractPTexts(html) {
    // Parse the HTML string into a DOM document
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Select all p elements
    const pElements = doc.querySelectorAll("p");

    // Extract the text content of each p element
    const pTexts = Array.from(pElements).map((p) => p.textContent);

    return pTexts;
  }

  useEffect(() => {
    if (!pageContent) return;
    // console.log(pageContent);
    let content = pageContent[0].content.rendered;
    let images = extractImgAttributes(content);
    // console.log(images);

    let text = extractPTexts(content);

    setWeddingTitle(pageContent[0].title.rendered);
    setGalleryImages(images);
    setPageText(text);

    // define image gallery, title and text content from
  }, [pageContent]);

  const Gallery = () => {
    if (!galleryImages) {
      return;
    }

    return (
      <>
        <PhotoAlbum
          layout="columns"
          photos={galleryImages}
          onClick={({ index: current }) => setIndex(current)}
        />
        <Lightbox
          index={index}
          slides={galleryImages}
          open={index >= 0}
          close={() => setIndex(-1)}
        />
      </>
    );
  };

  const WeddingIntroText = () => {
    if (!pageText) {
      return;
    }

    return (
      <>
        {pageText.map((p) => {
          return <p>{p}</p>;
        })}
      </>
    );
  };
  console.log(pageContent);
  if (!pageContent) return <></>;
  return (
    <div>
      {isSmall ? <SmallMenu /> : <Menu />}
      {/* <HalfScreen imageUrl={pageContent[0].fimg_url} title={"About"} /> */}
      <div className="full-width-content-wrapper">
        <div className="full-width-content padding-top-200">
          <h2>{weddingTitle}</h2>
          <WeddingIntroText />
        </div>
        <div className="gallery">
          <Gallery />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WeddingPage;
