import React, { useEffect, useState, useRef } from "react";

import { useLocation, Link } from "react-router-dom";

import "./menu.styles.scss";

const Menu = () => {
  return (
    <div className="menu-wrapper">
      <div className="menu-wrapper-component">
        <Link to={`/`}>Home</Link>
      </div>

      <div className="menu-wrapper-component">
        <Link to={`/about`}>About</Link>
      </div>
      <div className="menu-wrapper-component">
        <Link to={`/portfolio`}>Portfolio</Link>
      </div>
      <div className="menu-wrapper-logo">
        <img
          className="menu-logo"
          src={process.env.PUBLIC_URL + "/img/logo_icon_01.png"}
        />
      </div>
      <div className="menu-wrapper-component">
        <Link to={`/services`}>Services</Link>
      </div>
      <div className="menu-wrapper-component">
        <Link to={`/venues`}>Venues</Link>
      </div>
      <div className="menu-wrapper-component">
        <Link to={`/contact`}>Get in touch</Link>
      </div>
    </div>
  );
};

export default Menu;
