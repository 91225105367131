import React, { useEffect, useState, useRef } from "react";

import "./page.styles.scss";

import Menu from "../components/menu.component";
import SmallMenu from "../components/menu-small.component";
import FullScreen from "../components/full-screen.component";
import Footer from "../components/footer.component";

import { useLocation, Link } from "react-router-dom";

const PortfolioPage = ({ isSmall }) => {
  const [postContent, setPostContent] = useState(null);
  const [portfolioPageContent, setPortfolioPageContent] = useState(null);
  const [pageImages, setPageImages] = useState(null);
  const [pageText, setPageText] = useState(null);
  // const [frontpageImageContent, setFrontpageImageContent] = useState(null);

  useEffect(() => {
    async function loadPortfolioPage() {
      const response = await fetch(
        "https://dreamingoutloud.it/wordpress/wp-json/wp/v2/pages/?slug=portfolio"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setPortfolioPageContent(posts);
    }

    async function loadPosts() {
      const response = await fetch(
        "https://dreamingoutloud.it/wordpress/wp-json/wp/v2/posts"
      );
      if (!response.ok) {
        // oups! something went wrong
        return;
      }

      const posts = await response.json();
      setPostContent(posts);
    }
    loadPortfolioPage();
    loadPosts();
  }, []);

  function extractImgsAndSpecificTags(html) {
    // Parse the HTML string into a DOM document
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Select all figure elements and img tags within them
    const figureElements = doc.querySelectorAll("figure");

    // Array to store img tags
    const imgSrcs = [];

    // Loop through each figure element
    figureElements.forEach((figure) => {
      const img = figure.querySelector("img");
      if (img) {
        imgSrcs.push(img.getAttribute("src"));
        // imgTags.push(img.outerHTML); // Add img tag to array
        figure.remove(); // Remove the figure element from the document
      }
    });

    // Select all p and h2 elements remaining in the document
    const remainingElements = Array.from(doc.querySelectorAll("p, h2")).map(
      (el) => el.outerHTML
    );

    return { imgSrcs, remainingElements };
  }

  useEffect(() => {
    if (!portfolioPageContent) return;
    // console.log(pageContent);
    let content = portfolioPageContent[0].content.rendered;
    // let images = extractImgsAndRest(content);

    const result = extractImgsAndSpecificTags(content);
    console.log(result);
    setPageImages(result.imgSrcs);
    setPageText(result.remainingElements);

    // define image gallery, title and text content from
  }, [portfolioPageContent]);

  const FrontpageWedding = ({ content }) => {
    // console.log("hello");
    console.log(content);
    return (
      <div className="frontpage-single-wedding">
        <Link to={"/portfolio/" + content.slug}>
          <div className="frontpage-single-wedding-image">
            <img src={content.fimg_url} />
          </div>
          <div className="frontpage-single-wedding-title">
            {content.title.rendered}
          </div>
        </Link>
        {/* <div className="frontpage-single-wedding-description">
          {content.text}
        </div> */}
      </div>
    );
  };

  if (!postContent || !pageText) return <></>;
  return (
    <div>
      {isSmall ? <SmallMenu /> : <Menu />}
      <FullScreen imageUrl={portfolioPageContent[0].fimg_url} title={""} />
      <div className="frontpage-content-wrapper">
        <div className="frontpage-content">
          <div className="frontpage-image-wrapper">
            {pageImages.map((image) => {
              return (
                <div className="wp-block-image">
                  <img src={image} />
                </div>
              );
            })}
          </div>
          <div className="frontpage-text-wrapper">
            {pageText.map((el) => {
              return (
                <div
                  className="text-element"
                  dangerouslySetInnerHTML={{
                    __html: el,
                  }}
                ></div>
              );
            })}
          </div>
        </div>

        <div className="frontpage-wedding-examples">
          {/* {console.log(testContent)} */}
          {postContent.map((post) => {
            return <FrontpageWedding content={post} />;
          })}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PortfolioPage;
